<template>      
	<div class="mb-4">
		<div class="row">
			<div class="col-12" v-if="form_message !== ''">
				<ErrorAlert :messageI18n="form_message" />
			</div>
			<div class="col-6">
				<div class="mb-2">
					<label class="mb-1" for="tiers_id">{{ $t('tiers.billing_entity') }} *</label>
					<e-select
						v-model="default_form.invoicedetails_author"
						id="entity_id"
						track-by="tiers_id"
						label="tiers_rs"
						:placeholder="labelTitleEntity"
						:selectedLabel="selectedLabel"
						:options="entity"
						:searchable="true"
						:allow-empty="false"
						:loading="isLoadingEntity"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
			</div>

			<div class="col-6">
				<div class="mb-2">
							<label class="mb-1" for="horse_id">{{ $t('horse.horse') }}</label>
							<e-select
								v-model="default_form.invoicedetails_horse"
								track-by="name"
								label="name"
								:placeholder="labelTitleHorse"
								:selectedLabel="selectedLabel"
								:options="horses"
								:searchable="true"
								:allow-empty="false"
								:show-labels="false"
								:disabled="true"
							>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
						</div>
				
			</div>

			<div class="col-12">
				<div class="row">

					<div class="col-12 col-md-4">
						<div class="mb-2">
							<label class="mb-1" for="tiers_id">{{ $t('tiers.tiers') }} *</label>
							<e-select
								v-model="default_form.invoicedetails_tiers"
								track-by="tiers_id"
								label="tiers_rs"
								:placeholder="labelTitleTiers"
								:selectedLabel="selectedLabel"
								:options="tiers"
								:searchable="true"
								:allow-empty="false"
								:loading="isLoadingTiers"
								:show-labels="false"
								:multiple="true"
							>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} {{ option.tiers_postalcode }}</template>
								<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} {{ option.tiers_postalcode }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
						</div>
					</div>

					<div v-for="tier, index in default_form.invoicedetails_tiers" :key="index" class="col-12 col-md-4 mt-2">
						<div>{{ tier.tiers_rs }}</div>
						<div>
							<label class="mb-1">{{ $t('invoice.ref_client') }}</label>
							<input type="text" class="form-control" v-model="default_form.invoicedetails_refs_client[index]">
						</div>

						<div>
							<label class="mb-1">{{ $t('invoice.dossier_interne') }}</label>
							<input type="text" class="form-control" v-model="default_form.invoicedetails_dossiers_interne[index]">
						</div>
					</div>
					
				</div>
			</div>
	
			<template v-if="default_form.invoicedetails_tiers && default_form.invoicedetails_author && show_article">
				<div class="col-12" v-if="!isAddArticle">
					<div class="mb-2">
						<label class="mb-1" for="search_article">{{ $t('invoice.article_a_facturer') }}</label>

						<SearchArticles
							:entity_id.sync="default_form.invoicedetails_author.tiers_id"
							:preselected.sync="default_form.invoicedetails_articles"
							:articles_selected.sync="on_article_selected"
						></SearchArticles>
						
					</div>
				</div>

				<div class="col-6" v-if="!isAddArticle && default_form.invoicedetails_horse && default_form.invoicedetails_tiershorseparttype !== 0">
					<div class="mb-2">
						<label for="ht_initial">{{ $t('compta.prix_initial') }} *</label>
						<PriceInput v-model="default_form.invoicedetails_ht_initial" :class="error.indexOf('ht initial') > -1 ? 'is-invalid' : ''" />
					</div>
				</div>
				<div class="col-6" v-if="!isAddArticle && default_form.invoicedetails_horse && default_form.invoicedetails_tiershorseparttype !== 0">
					<div class="mb-2">
						<label for="tiers_horse_part">{{ $t('compta.quote_part') }} *</label>
						<b-input-group>
							<template #append>
								<b-input-group-text>%</b-input-group-text>
							</template>
							<PriceInput v-model="default_form.invoicedetails_tiershorsepartpart" :class="error.indexOf('quote part') > -1 ? 'is-invalid' : ''" />
						</b-input-group>
					</div>
				</div>

				<div class="col-6" v-if="!isAddArticle">
					<div class="mb-2">
						<label class="mb-1" for="articles_ht">{{ $t('compta.form.tarif_ht') }} *</label>
						<PriceInput v-model="default_form.invoicedetails_htunit_before_discount" 
							:disabled="!isAddArticle && default_form.invoicedetails_horse != null && default_form.invoicedetails_tiershorseparttype !== 0"
							:class="error.indexOf('ht unit') > -1 ? 'is-invalid' : ''"
						/>
					</div>
				</div>

				<div class="col">
					<div class="mb-2">
						<label class="mb-1" for="articles_ht">{{ $t('compta.form.qte') }} *</label>
						<input type="text" class="form-control" v-model="default_form.invoicedetails_qte" required>
					</div>
				</div>
				
				<div class="col-12" v-if="!isAddArticle">
					<hr>
					<button class="btn btn-secondary btn-block mb-2 bnt-block" @click.prevent="addArticle"><font-awesome-icon :icon="['far', 'layer-plus']" /> {{ $t('invoice.creer_article') }}</button>
				</div>
				<div class="col-12" v-if="isAddArticle">
					<div class="row">
						<div class="col-12">
						    <div class="mb-2">
						        <label for="articles_articlestype" class="col-form-label mb-1">{{ $t("compta.form.articles_type") }} *</label>
						         <e-select
						            v-model="articles.articles_articlestype"
						            id="articles_articlestype"
						            track-by="articlestype_id"
						            label="articlestype_label"
						            :placeholder="labelTitleArticlesType"
						            :deselectLabel="deselectLabel"
						            :selectedLabel="selectedLabel"
						            :selectLabel="enter_to_select"
						            :options="articles_type"
						            :searchable="true"
						            :show-labels="false"
						            :allow-empty="false"
						            :class="{ 'is-invalid': error && error.indexOf('Erreur articles type') > -1 }"
						        >
						    	</e-select>
						    </div>
						</div>
					</div>
					<template v-if="articles.articles_articlestype != null">
						<div class="row">
							<div class="col-6">
								<div class="mb-2">
									<label class="mb-1" for="articles_label">{{ $t('compta.form.label_article') }} *</label>
									<input type="text" class="form-control" v-model="articles.articles_label" required>
								</div>
							</div>
							<div class="col-6">
								<div class="mb-2">
									<label class="mb-1" for="articles_code">{{ $t('compta.form.code_article') }} *</label>
									<input type="text" class="form-control" v-model="articles.articles_code" required>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-6">
								<div class="mb-2">
									<label class="mb-1" for="articles_ht">{{ $t('compta.form.tarif_ht') }} *</label>
									<input type="text" class="form-control" v-model="articles.articles_ht" required>
								</div>
							</div>
							<div class="col-6">
								<div class="mb-2">
									<label class="mb-1" for="articles_vat">{{ $t('compta.form.vat') }} *</label>

									<e-select
										v-model="articles.articles_vat"
										id="search_vat"
										track-by="value"
										label="name"
										:placeholder="labelTitleVat"
										:selectedLabel="selectedLabel"
										:options="vat"
										:searchable="true"
										:allow-empty="false"
										:loading="isLoadingVat"
										:show-labels="false"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
										<template slot="noOptions">{{ $t('global.list_empty') }}</template>
									</e-select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-12 col-sm-6">
								<div class="mb-2">
									<label for="articles_accountingaccount" class="col-form-label mb-1">{{ $t("compta.form.compte") }} *</label>
									<e-select
									    v-model="articles.articles_accountingaccount"
									    id="articles_accountingaccountfdvex"
									    track-by="id"
									    label="name"
									    :placeholder="labelTitleCompteComptable"
									    :selectedLabel="selectedLabel"
									    :selectLabel="enter_to_select"
									    :options="comptes_comptables"
									    :searchable="true"
									    :show-labels="false"
									    :allow-empty="false"
									    :class="{ 'is-invalid': error && error.indexOf('Erreur compte comptable') > -1 }"
									>
									    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
									    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
									</e-select>
								</div>
							</div>

							<div class="col-12 col-sm-6">
							    <div class="mb-2">
							        <label for="articles_vataccount" class="col-form-label mb-1">{{ $t("compta.form.vat_compte_comptable") }} *</label>
							        <e-select
							            v-model="articles.articles_vataccount"
							            id="articles_vataccount"
							            track-by="id"
							            label="name"
							            :placeholder="labelTitleCompteComptable"
							            :deselectLabel="deselectLabel"
							            :selectedLabel="selectedLabel"
							            :selectLabel="enter_to_select"
							            :options="comptes_comptables_vat"
							            :searchable="true"
							            :show-labels="false"
							            :allow-empty="false"
							            :class="{ 'is-invalid': error && error.indexOf('Erreur vat compte comptable') > -1 }"
							        >
							            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
							        </e-select>
							        
							    </div>
							</div>

							<div class="col-12 col-sm-6">
							    <div class="mb-2">
							        <label for="articles_accountingaccountfdvxp" class="col-form-label mb-1">{{ $t("compta.form.articles_accountingaccountfdvxp") }} *</label>
							        <e-select
							            v-model="articles.articles_accountingaccountfdvxp"
							            id="articles_accountingaccountfdvxp"
							            track-by="id"
							            label="name"
							            :placeholder="labelTitleCompteComptable"
							            :deselectLabel="deselectLabel"
							            :selectedLabel="selectedLabel"
							            :selectLabel="enter_to_select"
							            :options="comptes_comptables"
							            :searchable="true"
							            :show-labels="false"
							            :allow-empty="false"
							            :class="{ 'is-invalid': error && error.indexOf('Erreur compte comptable fdvxp') > -1 }"
							        >
							            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
							        </e-select>
							    </div>
							</div>


							<div class="col-12 col-sm-6">
							    <div class="mb-2">
							        <label for="articles_vataccountfdvxp" class="col-form-label mb-1">{{ $t("compta.form.articles_vataccountfdvxp") }} *</label>
							        <e-select
							            v-model="articles.articles_vataccountfdvxp"
							            id="articles_vataccountfdvxp"
							            track-by="id"
							            label="name"
							            :placeholder="labelTitleCompteComptable"
							            :deselectLabel="deselectLabel"
							            :selectedLabel="selectedLabel"
							            :selectLabel="enter_to_select"
							            :options="comptes_comptables_vat"
							            :searchable="true"
							            :show-labels="false"
							            :allow-empty="false"
							            :class="{ 'is-invalid': error && error.indexOf('Erreur vat compte comptable fdvxp') > -1 }"
							        >
							            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
							        </e-select>
							    </div>
							</div>


							<div class="col-12 col-sm-6">
							    <div class="mb-2">
							        <label for="articles_accountingaccountfdvex" class="col-form-label mb-1">{{ $t("compta.form.articles_accountingaccountfdvex") }} *</label>
							        <e-select
							            v-model="articles.articles_accountingaccountfdvex"
							            id="articles_accountingaccountfdvex"
							            track-by="id"
							            label="name"
							            :placeholder="labelTitleCompteComptable"
							            :deselectLabel="deselectLabel"
							            :selectedLabel="selectedLabel"
							            :selectLabel="enter_to_select"
							            :options="comptes_comptables"
							            :searchable="true"
							            :show-labels="false"
							            :allow-empty="false"
							            :class="{ 'is-invalid': error && error.indexOf('Erreur compte comptable fdvex') > -1 }"
							        >
							            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
							        </e-select>
							    </div>
							</div>

							<div class="col-12 col-sm-6">
							    <div class="mb-2">
							        <label for="articles_vataccountfdvex" class="col-form-label mb-1">{{ $t("compta.form.articles_vataccountfdvex") }} *</label>
							        <e-select
							            v-model="articles.articles_vataccountfdvex"
							            id="articles_vataccountfdvex"
							            track-by="id"
							            label="name"
							            :placeholder="labelTitleCompteComptable"
							            :deselectLabel="deselectLabel"
							            :selectedLabel="selectedLabel"
							            :selectLabel="enter_to_select"
							            :options="comptes_comptables_vat"
							            :searchable="true"
							            :show-labels="false"
							            :allow-empty="false"
							            :class="{ 'is-invalid': error && error.indexOf('Erreur vat compte comptable fdvex') > -1 }"
							        >
							            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
							        </e-select>
							    </div>
							</div>
						</div>
					</template>
				</div>

				<div class="col-12">
					<b-form-checkbox id="discount_checkbox" v-model="has_discount">
						<label class="mb-2" for="discount_checkbox">{{ $t("compta.form.appliquer_remise") }}</label>
					</b-form-checkbox>
				</div>
				<div class="col-12" v-if="has_discount">
					<div class="row">
						<div class="col-6">
							<label class="mb-1" for="discount_type">{{ $t('compta.form.discount_type') }}</label>
							<e-select
								v-model="default_form.discount_type"
								id="discount_type"
								track-by="invoicediscounttype_id"
								label="invoicediscounttype_label"
								:placeholder="labelTitleDiscount"
								:selectedLabel="selectedLabel"
								:options="discount_type_formatted"
								:searchable="true"
								:allow-empty="false"
								:loading="isLoadingDiscount"
								:show-labels="false"
							>
								<template slot="singleLabel" slot-scope="{ option }">{{ $t(option.invoicediscounttype_label) }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
						</div>
						<div class="col-6">
							<div class="mb-2">
								<label class="mb-1" for="invoicedetails_discount_value">{{ $t('compta.form.discount_value') }}</label>
								<input type="text" class="form-control" v-model="default_form.invoicedetails_discount_value">
							</div>
						</div>
					</div>
				</div>

				<div class="col-12" v-if="code_analytics.length > 0">
					<div class="mb-2">
						<label>{{ $t('compta.form.code_analytics') }}</label>
						<e-select
							v-model="invoice_code_analytics"
							track-by="accountingcode_label"
							label="accountingcode_label"
							:placeholder="labelTitle"
							:selectedLabel="selectedLabel"
							:options="code_analytics"
							:searchable="true"
							:allow-empty="true"
							:loading="isLoading"
							:show-labels="false"
						>
							<template slot="singleLabel" slot-scope="{ option }">{{ option.accountingcode_label }}</template>
							<template slot="noOptions">{{ $t('global.list_empty') }}</template>
						</e-select>
					</div>
				</div>

				<div class="col-12">
					<div class="mb-2">
						<label class="mb-1" for="commentaire">{{ $t('invoice.commentaire') }}</label>
						<e-editor-froala
							ref="froala_article"
							identifier="invoicedetails_description"
							:placeholder="getTrad('invoice.commentaire')"
							:content="default_form.invoicedetails_description"
							@change="updateEditorComment"
							:moreText="[ 'bold', 'italic', 'strikeThrough', 'underline', 'undo', 'redo', 'formatUL', 'formatOL', 'clearFormatting' ]"
						></e-editor-froala>
						<!-- <textarea id="commentaire" class="form-control" rows="3" v-model="default_form.invoicedetails_description"></textarea> -->
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TableAction from "@/mixins/TableAction.js"
	import Accounting from "@/mixins/Accounting.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
    import Article from "@/mixins/Article.js"
    import Invoice from "@/mixins/Invoice.js"
    import Tiers from "@/mixins/Tiers.js"
    import Horse from "@/mixins/Horse.js"

	import Queue from '@/assets/js/utils/queue'


	export default {
		name: "LigneVierge",
		mixins: [Config, TableAction, Accounting, Navigation, Article, Invoice, Tiers, Horse],
		props: {
			options: {
				type: Object,
				default: () => ({
					type: 'Invoice',
					id: null,
				})
			},
			horse:{},
			processing: {},
			ready: {},
			bons: {},
			preselect_infos: {},
		},
		data () {
			return {
				form_message: '',
				isAddArticle: false,
				has_discount: false,
				discount_type: [],
				code_analytics: [],
				invoice_code_analytics: {},
				on_article_selected : null,
				default_form: {
					invoicedetails_id: null,
					invoicedetails_description: null,
					invoicedetails_label: null,
					invoicedetails_ht: null,
					invoicedetails_htunit: null,
					invoicedetails_qte: 1,
					invoicedetails_vat: 1,
					invoicedetails_horse: null,
					invoicedetails_articles: null,
					invoicedetails_actes: null,
					invoicedetails_tiers: [],
					invoicedetails_author: null,
					// invoicedetails_exportvat: null,
					invoicedetails_htunit_before_discount: null,
					invoicedetails_ht_before_discount: null,
					invoicedetails_discount_value: null,
					invoicedetails_discount_type: null,
					invoicedetails_ht_initial: null,
					invoicedetails_refs_client: [],
					invoicedetails_dossiers_interne: [],
					invoicedetails_tiershorseparttype: 4,
					invoicedetails_tiershorsepartpart: 100,
					discount_type: null,
				},
				articles: {
					articles_label: null,
					articles_code: null,
					articles_ht: null,
					articles_vat: null,
					articles_accountingaccount: null,
					articles_accountingaccountfdvex: null,
					articles_accountingaccountfdvxp: null,
					articles_vataccount: null,
					articles_vataccountfdvex: null,
					articles_vataccountfdvxp: null,
					articles_articlestype: null,
				},

				entity_id: null,
				tiers_id: null,
				horse_id: null,
				articles_id: null,

				default_tiers: [],
				tiers: [],
				default_entity: [],
				entity: [],
				error: [],
				horses: [],
				options_articles: [],

				isLoadingEntity: false,
				isLoadingTiers: false,
				isLoadingHorse: false,
				isLoadingVat: false,
				isLoadingDiscount: false,
				isLoadingCompteComptable: false,
				isLoading: false,
				loadingQueue: new Queue(),

				cache: {
					horses: {},
					comptes_comptables: {},
					articles: {}
				},

				comptes_comptables: [],
                comptes_comptables_vat: [],
				vat: [],
				vat_filtred: [],
				vats_export: [],
				articles_type: [],
				first_loaded: false,
				show_article: false,

				labelTitleTiers: this.getTrad("tiers.rechercher"),
				labelTitleVat: this.getTrad("vat.rechercher"),
				labelTitleCompteComptable: this.getTrad("accounting_account.rechercher"),
				labelTitleEntity: this.getTrad("tiers.rechercher_billing_entity"),
				labelTitleHorse: this.getTrad("horse.rechercher_cheval"),
				labelTitle: this.getTrad("invoice.ligne_vierge_search_article"),
				labelTitleArticlesType: this.getTrad("compta.search_articles_type"),
				labelTitleDiscount: this.getTrad("invoice.ligne_vierge_search_article"),

				labelNotFound: this.getTrad("invoice.ligne_vierge_no_article_found"),
				labelSearchPlaceholder: this.getTrad("invoice.ligne_vierge_rechercher"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				selectedLabel: this.getTrad("global.selected_label"),
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
			}
		},
		mounted() {
			this.init_component()
		},
		created () {
		},
		methods: {
			async init_component() {
				
				//GFE, on doit preselectionner la remise en pourcentage
				this.default_form.discount_type = this.discount_type[0]
				

				this.default_form.invoicedetails_horse = {
					id: this.horse.horse_id,
					name: this.horse.horse_nom,
				}

				this.vat = []

				const vat = await this.getVat()
				for (let i = 0; i < vat.length; i++) {
					this.vat.push({
						id: vat[i].vat_id,
						name: vat[i].vat_label,
						value: vat[i].vat_value,
					})
				}

				if(Object.keys(this.preselect_infos).length > 0)
				{
					if(this.preselect_infos.entity)
					{
						this.entity_id = this.preselect_infos.entity.tiers_id
						this.default_form.invoicedetails_author = {
							tiers_id: this.preselect_infos.entity.tiers_id,
							tiers_rs: this.preselect_infos.entity.tiers_rs,
						}
					}

					if(this.preselect_infos.tiers)
					{
						this.tiers_id = this.preselect_infos.tiers.map(tier => tier.tiers_id)
						for( let tier of this.preselect_infos.tiers)
						this.default_form.invoicedetails_tiers.push({
							tiers_id: tier.tiers_id,
							tiers_rs: tier.tiers_rs,
						})
					}
					
					if(this.preselect_infos.horse)
					{
						this.horse_id = this.preselect_infos.horse.horse_id
						this.default_form.invoicedetails_horse = {
							id: this.preselect_infos.horse.horse_id,
							name: this.preselect_infos.horse.horse_nom,
						}
					}
				}

				this.discount_type = await this.loadDiscountType()

				this.loadAllTiers()
			},

			async addArticle(){
				this.isAddArticle = true
				let articles_type = await this.getArticlesType()
				for (let i = 0; i < articles_type.length; i++) {
					this.articles_type.push({
						articlestype_id: articles_type[i].articlestype_id,
						articlestype_label: this.getTrad(articles_type[i].articlestype_label),
						articlestype_code: articles_type[i].articlestype_code,
					})
				}
			},

			async loadAllTiers(){
				this.isLoadingTiers = true
				this.isLoadingEntity = true
				this.$emit('update:ready', false)

				this.tiers = await this.loadTiers()
				this.default_tiers = this.deppCloneObj(this.tiers)
				this.entity = await this.loadTiersEntity()
				this.default_entity = this.deppCloneObj(this.entity)

				if(!this.default_form.invoicedetails_author && this.entity.length > 0) {
					this.default_form.invoicedetails_author = this.entity[0]
				}

				if(this.entity.length == 1) {
					let accountingplan_default = await this.loadAccountingPlanDefault()
					if(accountingplan_default) {
						this.default_form.invoicedetails_author = {
							tiers_id: accountingplan_default.tiers.tiers_id,
							tiers_rs: accountingplan_default.tiers.tiers_rs
						}
					}
				}

				this.$emit('update:ready', true)
				this.isLoadingTiers = false
				this.isLoadingEntity = false
			},

			async loadAllAfterTiers() {
				await this.loadingQueue.flush()
				return this.loadingQueue.enqueue(() => {
					return this._loadAllAfterTiers(this.default_form.invoicedetails_author.tiers_id)
				})
			},

			async _loadAllAfterTiers(author_id){
				this.isLoading = true
				this.$emit('update:ready', false)

				// Gestion cache compte comptable
				if(Object.keys(this.cache.comptes_comptables).length == 0) {
					this.cache.comptes_comptables = {}
				}

				if(this.cache.comptes_comptables[author_id] == null) {
					this.loadAccountingAccountsTiers(author_id).then(comptes_comptables => {
						this.display_compte_comptable(comptes_comptables)
						this.cache.comptes_comptables[author_id] = comptes_comptables
					})
				}
				else {
					const comptes_comptables = this.cache.comptes_comptables[author_id]
					this.display_compte_comptable(comptes_comptables)
				}

				

				// Gestion cache article
				if(Object.keys(this.cache.articles).length == 0) {
					this.cache.articles = {}
				}


				this.options_articles = []
				this.default_form.invoicedetails_articles = null
				// Si j'ai un id, je suis dans la modif je préselect

				this.default_form.articles = null
				this.default_form.invoicedetails_qte = 1
				this.default_form.invoicedetails_discount_value = null
				this.default_form.invoicedetails_ht_before_discount = null
				this.default_form.invoicedetails_htunit_before_discount = null
				this.default_form.invoicedetails_ht_initial = null
				this.show_article = false
				
				

				this.code_analytics = await this.getAnalyticsCode(author_id)

				if(Object.keys(this.preselect_infos).length > 0)
				{
					if(this.preselect_infos.article)
					{
						this.articles_id = this.preselect_infos.article.articles_id

						this.invoicedetails_ht = this.preselect_infos.invoice_details.invoicedetails_ht
						this.invoicedetails_ht_initial = this.preselect_infos.invoice_details.invoicedetails_ht_initial
						this.default_form.invoicedetails_qte = this.preselect_infos.invoice_details.invoicedetails_qte
						if(this.code_analytics.length > 0)
						{
							this.invoice_code_analytics = this.code_analytics.find(elem => elem.accountingcode_label == this.preselect_infos.invoice_details.invoicedetails_code_analytics)
						}
						
						this.default_form.invoicedetails_discount_value = this.preselect_infos.invoice_details.invoicedetails_discount_value
						this.default_form.invoicedetails_ht_before_discount = this.preselect_infos.invoice_details.invoicedetails_ht_before_discount
						this.default_form.invoicedetails_htunit_before_discount = this.preselect_infos.invoice_details.invoicedetails_htunit_before_discount

						this.invoicedetails_ht = this.preselect_infos.invoice_details.invoicedetails_ht
						
						if(this.preselect_infos.invoice_details.invoicedetails_discount_type != null)
						{
							this.has_discount = true
							this.default_form.discount_type = {
								invoicediscounttype_id: this.preselect_infos.invoice_details.discount_type.invoicediscounttype_id,
								invoicediscounttype_label: this.preselect_infos.invoice_details.discount_type.invoicediscounttype_label,
								invoicediscounttype_type: this.preselect_infos.invoice_details.discount_type.invoicediscounttype_type,
							}
						}

						this.default_form.invoicedetails_articles = this.preselect_infos.article
					}
				}

				this.isLoading = false
				this.first_loaded = true
				this.show_article = true
				this.$emit('update:ready', true)
			},

			display_compte_comptable(comptes_comptables){
				this.comptes_comptables_vat = []
				this.comptes_comptables = []
				for (let i = 0; i < comptes_comptables.length; i++) {
					if(comptes_comptables[i].accountingaccount_vat == null) {
						this.comptes_comptables.push({
							id: comptes_comptables[i].accountingaccount_id,
							name: comptes_comptables[i].accountingaccount_number + " ( " + comptes_comptables[i].accountingaccount_label + " )",
						})
					}
					else
					{
						this.comptes_comptables_vat.push({
						    name: comptes_comptables[i].accountingaccount_number + " ("+ comptes_comptables[i].accountingaccount_label +")", 
						    id: comptes_comptables[i].accountingaccount_id })
					}
				}
			},

			async loadVatCode(){
				if(this.entity_id && this.tiers_id) {
					for (let index = 0; index < this.tiers_id.length ; index++) {
						//Je vais appeler l'api pour récuperer le code de tva à appliquer
						this.default_form.invoicedetails_tiers[index].invoicedetails_codevat = await this.getVatCode(this.entity_id, this.tiers_id[index])
					}
				}
			},

			async checkForm(){
				this.$emit('update:processing', false)
				if(!this.processing)
				{
					this.processing = true
					this.$emit('update:processing', true)
					this.error = []
					let error_values = []

					if(this.isAddArticle)
					{
						if(this.articles.articles_vat === null)
						{
							this.error.push("Erreur vat")
						}

						if(this.articles.articles_accountingaccount === null)
						{
							this.error.push("Erreur accounting account")
						}

						if(this.articles.articles_accountingaccountfdvex === null)
						{
							this.error.push("Erreur accounting account fdvex")
						}

						if(this.articles.articles_accountingaccountfdvxp === null)
						{
							this.error.push("Erreur accounting account fdvxp")
						}

						if(this.articles.articles_vataccount === null)
						{
							this.error.push("Erreur vat accounting account")
						}

						if(this.articles.articles_vataccountfdvex === null)
						{
							this.error.push("Erreur vat accounting account fdvex")
						}

						if(this.articles.articles_vataccountfdvxp === null)
						{
							this.error.push("Erreur vat accounting account fdvxp")
						}

						if(!this.articles.articles_label)
						{
							this.error.push("Erreur accounting label")
						}

						if(!this.articles.articles_code)
						{
							this.error.push("Erreur accounting code")
						}

						if(!this.articles.articles_ht)
						{
							this.error.push("Erreur accounting ht")
						}
					}
					else if(this.default_form.invoicedetails_articles == null)
					{
						this.error.push("Erreur no article selected")
					}
					
					if(this.default_form.invoicedetails_qte.toString().indexOf(",") > -1)
					{
						this.default_form.invoicedetails_qte = this.default_form.invoicedetails_qte.toString().substring(0, this.default_form.invoicedetails_qte.toString().indexOf(",") + 3)
					}
					else if(this.default_form.invoicedetails_qte.toString().indexOf(".") > -1)
					{
						this.default_form.invoicedetails_qte = this.default_form.invoicedetails_qte.toString().substring(0, this.default_form.invoicedetails_qte.toString().indexOf(".") + 3)
					}

					if(!this.isAddArticle && this.default_form.invoicedetails_horse) {
						if(!this.default_form.invoicedetails_ht_initial) {
							this.error.push("ht initial")
						}
						if(!this.default_form.invoicedetails_tiershorsepartpart) {
							this.error.push("quote part")
						}
					}
					else if(!this.isAddArticle && !this.default_form.invoicedetails_htunit_before_discount) {
						this.error.push("ht unit")
					}

					if(this.error.length === 0 && error_values.length === 0)
					{
						this.form_message = ""
						let qte = this.default_form.invoicedetails_qte
						let htunit = this.default_form.invoicedetails_htunit_before_discount
						let discount_value = (this.has_discount) ? this.default_form.invoicedetails_discount_value : null
						let discount_type = (this.has_discount) ? this.default_form.discount_type : null
						let label = this.default_form.invoicedetails_label
						let description = this.default_form.invoicedetails_description
						let export_vat = this.default_form.invoicedetails_exportvat
						let code_analytics = (this.invoice_code_analytics) ? this.invoice_code_analytics.accountingcode_label : null
						let articles_id = null
						if(this.default_form.invoicedetails_articles != null)
						{
							articles_id = this.default_form.invoicedetails_articles.articles_id
						}

						if(this.isAddArticle)
						{
							let vat = this.vat.find(vat => { return vat.id == this.articles.articles_vat.id })
							const tva = parseFloat(vat.value)
							const ht = parseFloat(this.articles.articles_ht)
							let ttc = (vat.value * ht) + ht

							let result = null
							result = await this.addArticles(
								this.articles.articles_accountingaccount.id,
								this.articles.articles_vat.id,
								this.articles.articles_label,
								this.articles.articles_code,
								this.articles.articles_ht,
								ttc,
								this.articles.articles_articlestype.articlestype_id,
								this.articles.articles_accountingaccountfdvex.id,
								this.articles.articles_accountingaccountfdvxp.id,
								this.articles.articles_vataccount.id,
								this.articles.articles_vataccountfdvex.id,
								this.articles.articles_vataccountfdvxp.id
							);
							
							htunit = this.articles.articles_ht
							this.default_form.invoicedetails_ht_initial = htunit
							vat = this.articles.articles_vat.id
							label = this.articles.articles_label
							
							if(result)
							{
								articles_id = result.retour.articles_id
							}
							else
							{
								this.form_message = "error.LEP"

								this.$emit('update:processing', false)
								return false;
							}
						}

						let paramsToSend = {};
						paramsToSend['details'] = []

						let infos = {
							author_id : this.entity_id,
							tiers_ids : this.tiers_id,
							htunit : htunit,
							qte : qte,
							label : label,
							description : description,
							horse_id : this.horse_id,
							articles_id : articles_id,
							export_vats: this.vats_export,
							export_vat: export_vat,
							tiershorseparttype: this.default_form.invoicedetails_tiershorseparttype,
							tiershorsepartpart: this.default_form.invoicedetails_tiershorsepartpart,
							refs_clients: this.default_form.invoicedetails_refs_client,
							dossiers_interne: this.default_form.invoicedetails_dossiers_interne,
							discount_value: (this.has_discount) ? discount_value : null,
							discount_type: (this.has_discount) ? discount_type : null,
							code_analytics: code_analytics
						}

						infos.ht_initial = this.default_form.invoicedetails_ht_initial * 100
						paramsToSend.details.push(infos)
						paramsToSend.from_ligne_vierge = true
						const result_invoice_details = await this.createMultipleInvoiceDetails(paramsToSend);

						if(result_invoice_details)
						{
							this.$emit('update:processing', false)
							this.$emit('TableAvenantArticle::ReloadArticle', this._uid)
							return result_invoice_details
						}
						else
						{
							this.failureToast();
						}
					}
					else if(error_values.length > 0) 
					{
						this.form_message = "formulaire.erreur_champs_mal_remplis"
						this.failureToast('formulaire.erreur_champs_mal_remplis')
					}
					else
					{
						this.form_message = "formulaire.erreur_champs_non_remplis"
						this.failureToast('formulaire.erreur_champs_non_remplis')
					}

					this.$emit('update:processing', false)
					return false;
				}
			},
			onArticleSelection(value) {
				if(value != undefined)
				{
					this.articles_id = value
					let article_selected = value
					if(article_selected != undefined)
					{
						this.default_form.invoicedetails_vat = article_selected.vat.vat_id

						//Si c'est pas une pension je mets à jour le nom de l'article ainsi que son prix, car sinon ca pose problème avec le label et le prix
						if(this.default_form.invoicedetails_horsepensionperiode == null)
						{
							this.default_form.invoicedetails_ht_initial = article_selected.articles_ht / 100
							this.default_form.invoicedetails_htunit_before_discount = article_selected.articles_ht / 100
							this.default_form.invoicedetails_label = article_selected.articles_label
							this.default_form.invoicedetails_qte = article_selected.articles_quantite
							this.default_form.invoicedetails_description = article_selected.articles_commentaire
							this.$refs.froala_article.setValue(article_selected.articles_commentaire)
						}
					}
				}
			},
			fillVatFiltred(vat_id, type) {
				const vat_of_article = this.vat.find(vat => vat.id == vat_id)
				const vat_of_zero = this.vat.find(vat => vat.id == 1)

				for (let tier of this.default_form.invoicedetails_tiers) {
					this.vat_filtred = []
					if(type == 'produit')
					{
						vat_of_article.label = vat_of_article.name + " " + this.getTrad("compta.form.produit_non_exporte")
						this.vat_filtred.push(vat_of_article)
						this.vats_export.push(vat_of_article)
						
						if( tier.invoicedetails_codevat == "FDVXP" ||tier.invoicedetails_codevat == "FDVEX" || (tier.invoicedetails_tiers && tier.tiers_country != 75))
						{
							vat_of_zero.label = vat_of_zero.name + " " + this.getTrad("compta.form.produit_exporte")
							this.vat_filtred.push(vat_of_zero)
							this.vats_export.push(vat_of_zero)
						}
					}				
					if(type == 'service')
					{						
						vat_of_article.label = vat_of_article.name + " " + this.getTrad("compta.form.service_non_exporte")
						this.vat_filtred.push(vat_of_article)
						
						if(tier.invoicedetails_codevat == "FDVXP" || tier.invoicedetails_codevat == "FDVEX")
						{
							vat_of_zero.label = vat_of_zero.name + " " + this.getTrad("compta.form.service_exporte")
							this.vat_filtred.push(vat_of_zero)
							this.vats_export.push(vat_of_zero)
						}
						else
						{
							this.vats_export.push(vat_of_article)
						}
					}
				}
			},
			async calcul_htunit_before_discount() {

				if(this.default_form.invoicedetails_tiershorseparttype === 0){
					return false
				}
				if(this.default_form.invoicedetails_tiershorsepartpart == null || this.default_form.invoicedetails_tiershorsepartpart == ''){
					return false
				}
				if(this.default_form.invoicedetails_horse == null) {
					return false
				}
				if(this.default_form.invoicedetails_ht_initial == null || this.default_form.invoicedetails_ht_initial == ''){
					return false
				}

				const tiershorsepartpart = this.default_form.invoicedetails_tiershorsepartpart.toString().replace(',', '.').replace(/\s/g, '')
				let ht_initial = null
				if(this.default_form.invoicedetails_horse && this.default_form.invoicedetails_tiershorseparttype !== 0){
					ht_initial= this.default_form.invoicedetails_ht_initial.toString().replace(',', '.').replace(/\s/g, '')
				} else {
					ht_initial = this.default_form.invoicedetails_ht_before_discount.toString().replace(',', '.').replace(/\s/g, '')
				}

				this.default_form.invoicedetails_htunit_before_discount =  await this.priceFormat((ht_initial * (tiershorsepartpart / 100)))
			},
			updateEditorComment(val){
				this.default_form.invoicedetails_description = val.val
			},
			checkSameTiers(from) {
				if(from == 'author') {
					let tiers = []
					for (let i = 0; i < this.default_tiers.length; i++) {
						if(!this.default_form.invoicedetails_author || this.default_tiers[i].tiers_id != this.default_form.invoicedetails_author.tiers_id) {
							tiers.push(this.default_tiers[i])
						}
					}
	
					this.tiers = tiers
				} else {
					let entity = []
					for (let i = 0; i < this.default_entity.length; i++) {
						if(!this.default_form.invoicedetails_tiers || this.default_entity[i].tiers_id != this.default_form.invoicedetails_tiers.tiers_id) {
							entity.push(this.default_entity[i])
						}
					}

					this.entity = entity
				}

				if(!this.default_form.invoicedetails_author || !this.default_form.invoicedetails_tiers) {
					return false
				}

				if(this.default_form.invoicedetails_tiers.filter(tier => tier.tiers_id == this.default_form.invoicedetails_author.tiers_id).length == 0) {
					return false
				}

				if(from == 'author') {
					this.default_form.invoicedetails_tiers = null
					
				} else {
					this.default_form.invoicedetails_author = null
				}

				return true
			}
		},
		computed: {
			discount_type_formatted(){
				let tab = []

				for(let i = 0; i < this.discount_type.length; i++)
				{
					let current = this.discount_type[i]
					current.invoicediscounttype_label = this.getTrad(current.invoicediscounttype_label)
					tab.push(current)
				}

				return tab
			}
		},
		watch:{
			'default_form.invoicedetails_tiers'(value, old) {
				if(old == value) {
					return false
				}

				if(value) {
					this.checkSameTiers('tiers')
				}

				let tiers_id = []
				for(let tier of value ){
					if(tier.tiers_id != undefined) {
						tiers_id.push(tier.tiers_id)
					}
				}
				this.tiers_id = tiers_id

				this.loadVatCode()

				if(this.default_form.invoicedetails_articles != null && Object.keys(this.default_form.invoicedetails_articles).length > 0)
				{
					this.fillVatFiltred(this.default_form.invoicedetails_articles.vat.vat_id, this.default_form.invoicedetails_articles.articles_type.articlestype_code)
				}
			},
			'default_form.invoicedetails_author'(value, old) {
				if(old == value) {
					return false
				}

				if(value) {
					this.checkSameTiers('author')
				}

				if(value.tiers_id != undefined) {
					this.entity_id = value.tiers_id
					if(old == null || value.tiers_id != old.tiers_id) {
						this.loadAllAfterTiers()
					}
				}
				//Lorsque l'on charge une page depuis la fiche tiers, le premier calcul de la VAT est lancer d'ici
				if(!this.default_form.invoicedetails_codevat || this.default_form.invoicedetails_codevat == undefined){
					this.loadVatCode()
				}
			},
			'default_form.invoicedetails_horse'(value) {
				if(value.id != undefined) {
					this.horse_id = value.id
				}
			},
			'default_form.invoicedetails_articles'(value) {
				if(value != undefined && Object.keys(value).length > 0) {
					if(value.articles_id) {
						this.articles_id = value.articles_id
					}
					else {
						this.articles_id = value
					}
					
					this.fillVatFiltred(value.vat.vat_id, value.articles_type.articlestype_code)
				}
			},
			'default_form.invoicedetails_ht_initial'(value) {
				this.calcul_htunit_before_discount()
			},
			'default_form.invoicedetails_tiershorsepartpart'(value) {
				this.calcul_htunit_before_discount()
			},
			'articles.articles_label'() {
				this.articles.articles_code = this.accentsTidy(this.articles.articles_label).replace(/ /g, "_")
			},
			'articles.articles_vat'(value) {
				this.fillVatFiltred(value.id, this.articles.articles_articlestype.articlestype_code)
			},
			'vats_export'(val){
				for(const codeVAT of this.vats_export){
					this.default_form.invoicedetails_exportvat = []
					if((this.default_form.invoicedetails_codevat == "FDVXP" || this.default_form.invoicedetails_codevat == "FDVEX") && val.id == 1)
					{
						this.default_form.invoicedetails_exportvat.push(1)
					}
					else
					{
						this.default_form.invoicedetails_exportvat.push(0)
					}
				}
			},
			'articles.articles_accountingaccount'(val){
			    if(this.articles.articles_accountingaccountfdvxp == null || Object.keys(this.articles.articles_accountingaccountfdvxp).length == 0)
			    {
			        this.articles.articles_accountingaccountfdvxp = val
			    }

			    if(this.articles.articles_accountingaccountfdvex == null || Object.keys(this.articles.articles_accountingaccountfdvex).length == 0)
			    {
			        this.articles.articles_accountingaccountfdvex = val
			    }
			},
			'articles.articles_vataccount'(val){
			    if(this.articles.articles_vataccountfdvxp == null || Object.keys(this.articles.articles_vataccountfdvxp).length == 0)
			    {
			        this.articles.articles_vataccountfdvxp = val
			    }

			    if(this.articles.articles_vataccountfdvex == null || Object.keys(this.articles.articles_vataccountfdvex).length == 0)
			    {
			        this.articles.articles_vataccountfdvex = val
			    }
			},
			on_article_selected(val){
				this.default_form.invoicedetails_articles = val
				this.onArticleSelection(val)
			}
		},
		components: {
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			PriceInput: () => import('GroomyRoot/components/Inputs/PriceInput'),
			SearchArticles: () => import('@/components/Utils/SearchArticles')
		}
	}
</script>